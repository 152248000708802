import React, { useState, useEffect } from "react"
import "./contact.sass"
import { defaultIntl, isAu, Slider, window, getNowCountry } from "../../utils"
import classNames from "classnames"
import data from "./contact-list.json"
import { $getLocation } from "../../components/forms"
// images
import Alberta from "../../images/about/canada/Alberta.png"
import bc from "../../images/about/canada/bc.png"
import Ontario from "../../images/about/canada/Ontario.png"
import Quebec from "../../images/about/canada/Quebec.png"
import NAL from "../../images/about/canada/NAL.png"
import Manitoba from "../../images/about/canada/Manitoba.png"
import Saskatchewan from "../../images/about/canada/Saskatchewan.png"
import others from "../../images/about/canada/others.png"
import Melbourne from "../../images/about/au/melbourne.png"
import Sydney from "../../images/about/au/sydney.png"
import London from "../../images/about/uk/London.png"
import Sheffield from "../../images/about/uk/Sheffield.png"

const MapList = [{
  name: "British Columbia",
  path: bc
},
{
  name: "Alberta",
  path: Alberta
},
{
  name: "Saskatchewan",
  path: Saskatchewan
},
{
  name: "Manitoba",
  path: Manitoba
},
{
  name: "Ontario",
  path: Ontario
},
{
  name: "Quebec",
  path: Quebec
},
{
  name: "Newfoundland and Labrador",
  path: NAL
},
{
  name: "New Brunswick",
  path: others
},
{
  name: "Prince Edward Island",
  path: others
},
{
  name: "Nova Scotia",
  path: others
}
]
const AuMapList = [{
  name: 'Sydney',
  path: Sydney
},{
  name: 'Melbourne',
  path: Melbourne
}]
const AuAreas = [{
  ch_name: '悉尼',
  name: 'Sydney',
  province: 'Sydney',
},{
  ch_name: '墨尔本',
  name: 'Melbourne',
  province: 'Melbourne'
}]
const UkMapList = [{
  name: 'London',
  path: London
},{
  name: 'Sheffield',
  path: Sheffield
}]
const UkAreas = [{
  ch_name: '伦敦',
  name: 'London',
  province: 'London',
},{
  ch_name: '谢菲尔德',
  name: 'Sheffield',
  province: 'Sheffield'
}]

export default ({ intl = defaultIntl }) => {
  const [country, setCountry] = useState("ca")
  const [areas, setAreas] = useState([])

  useEffect(() => {
    $getLocation('/areas/get').then((res) => setAreas(res.data));
    const currentCountry = getNowCountry();
    if (isAu()) {
      setCountry("au")
    } else {
      setCountry(currentCountry)
    }
  }, []);
 
  return (
    <div className="contact" id="contact">
      <div className="container">
        <h3 className="section-title">{intl.formatMessage({ id: "about.contact.title" })}</h3>
        <p className="section-subtitle">{intl.formatMessage({ id: "about.contact.desc" })}</p>
        <p className="section-subtitle">
          <span className="section-info">{intl.formatMessage({ id: "about.contact.info" })}</span>
        </p>
        <div className="tabs is-toggle is-toggle-rounded">
          <ul>
            <li className={classNames({ "is-active": country === "ca" })} onClick={() => setCountry("ca")}>
              <a>
                <span>{intl.formatMessage({ id: "about.contact.country.ca" })}</span>
              </a>
            </li>
            <li className={classNames({ "is-active": country === "us" })} onClick={() => setCountry("us")}>
              <a>
                <span>{intl.formatMessage({ id: "about.contact.country.us" })}</span>
              </a>
            </li>
            <li className={classNames({ "is-active": country === "au" })} onClick={() => setCountry("au")}>
              <a>
                <span>{intl.formatMessage({ id: "about.contact.country.au" })}</span>
              </a>
            </li>
            <li className={classNames({ "is-active": country === "uk" })} onClick={() => setCountry("uk")}>
              <a>
                <span>{intl.formatMessage({ id: "about.contact.country.uk" })}</span>
              </a>
            </li>
          </ul>
        </div>
        {
          country === "ca" && <Country statesData={MapList} areas={areas.filter(area => area.country === 'Canada' && [2, 3].includes(area.level) && area.inMap)} intl={intl}/>
        }
        {
          country === "us" && <US statesData={data['US']} areas={areas.filter(area => area.country === 'United States' && [2, 3].includes(area.level) && area.inMap)} intl={intl}/>
        }
        {
          country=== "au" && <Country statesData={AuMapList} areas={AuAreas} intl={intl} country="au" />
        }
        {
          country=== "uk" && <Country statesData={UkMapList} areas={UkAreas} intl={intl} country="uk" />
        }
      </div>
    </div>
  )
}

const Country = ({ statesData = [], areas = [], intl = defaultIntl, country }) => {
  const [lang] = useState(intl.locale)
  const [slider, setSlider] = useState(null)
  const [current, setCurrent] = useState(0)
  const SliderSettings = {
    dots: false,
    slidesToShow: areas.length >= 3 ? 3 : 2,
    slidesToScroll: 1,
    infinite: areas.length > 1,
    vertical: true,
    centerPadding: "10px",
    verticalSwiping: true,
    afterChange: cur => {
      console.log(cur, 666);
      setCurrent(cur)
    },
  }

  const handlerSwitch = i => {
    if (areas[i].province) {
      let item = document.querySelector(".current")
      slider.slickGoTo(i)
      if (item) {
        item.classList.remove("current")
      }
    }
  }

  useEffect(() => {
    if (slider) {
      const slicks = document.getElementById("canada-slider").getElementsByClassName("slick-slide")
      for (let i = 0; i < slicks.length; i++) {
        slicks[i].style.width = ""
      }
    }
  }, [slider])

  return (
    <div className="columns country">
      <div className="column is-three-fifths">
        <div className="map-wrapper">
          {statesData.map((city, index) => (
            <img src={city.path} className={classNames({ show: areas.length ? city.name === areas[current].province : false, au: country === 'au' })} key={index} />
          ))}
        </div>
      </div>
      <div className={classNames("column is-two-fifths", {au: country === "au"})} id="canada-slider">
        <Slider {...SliderSettings} ref={slider => setSlider(slider)}>
        {areas.map((city, index) => {
          return (
            <div className={classNames("slide-item", { selected: current === index })} key={index} onTouchEnd={() => handlerSwitch(index)}>
              <div className="text-content">
                <h3 className="contact-name">{lang === 'zh' ? city.ch_name : city.name}</h3>
                {/**
                  <p>{intl.formatMessage({ id: "about.contact.phone" })}: {formatPhoneNumber(city.phone)}</p>
                  <p>Email: {city.email} </p>
                  **/}
                </div>
            </div>
          )
        })}
        </Slider>
        {
          !window.isMobile() && <div className="slider-controller">
            <div className="slider-btns">
              <i className={classNames("material-icons", { gray: current === 0 })} onClick={() => slider.slickPrev()}>
                chevron_left
              </i>
              <i className={classNames("material-icons", { gray: current === areas.length - 1 })} onClick={() => {
                console.log('current:', current);
                console.log('areas:', areas);
                if (current === areas[current].length - 1) {
                //
                } else slider.slickNext()
                }}>
                chevron_right
              </i>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const US = ({ statesData, areas = [], intl = defaultIntl }) => {
  const [lang] = useState(intl.locale)
  const [slider, setSlider] = useState(null)
  const [current, setCurrent] = useState(0)

  const SliderSettings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: areas.length > 3,
    vertical: true,
    centerPadding: "10px",
    verticalSwiping: true,
    afterChange: cur => {
      setCurrent(cur)
    },
  }

  const handlerSwitch = (i) => {
    let item = document.querySelector(".current")
    slider.slickGoTo(i)
    if (item) {
      item.classList.remove("current")
    }
  }
  useEffect(() => {
    if (slider) {
      const slicks = document.getElementById("us-slider").getElementsByClassName("slick-slide")
      for (let i = 0; i < slicks.length; i++) {
        slicks[i].style.width = ""
      }
    }
  }, [slider])

  if (!statesData) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <div className="columns country">
      <div className="column is-three-fifths">
        <div className="map-wrapper">
          <svg viewBox="0 0 960 600">
            {statesData.map((stateData, index) =>
              <path
                className=""
                style={{fill: areas.length && areas[current].province === stateData.name ? "#13C2C2" : "#EBEBEB"}}
                key={index}
                stroke="#fff"
                strokeWidth="6px"
                d={stateData.shape}
                onMouseOut={(event) => {
                  event.target.style.fill = '#EBEBEB';
                }}
              >
              </path>
            )}
          </svg>
        </div>
      </div>
      <div className="column is-two-fifths" id="us-slider">
        <Slider {...SliderSettings} ref={slider => setSlider(slider)}>
          {areas.map((city, index) => {
            return (
              <div className={classNames("slide-item", { selected: current === index })} key={index} onTouchEnd={() => handlerSwitch(index)}>
                <div className="text-content">
                  <h3 className="contact-name">{lang === 'zh' ? city.ch_name : city.name}</h3>
                </div>
              </div>
            )
          })}
        </Slider>
        {
          !window.isMobile() && <div className="slider-controller">
          <div className="slider-btns">
            <i className={classNames("material-icons", { gray: current === 0 })} onClick={() => slider.slickPrev()}>
              chevron_left
            </i>
            <i className={classNames("material-icons", { gray: current === areas.length - 1 })} onClick={() => slider.slickNext()}>
              chevron_right
            </i>
          </div>
          <div className="slider-dots">
            {Object.keys(areas).map((s, i) => {
              const sliderClass = ["slider-indicator"]
              if (i === current) {
                sliderClass.push("current")
              }
              return <span className={sliderClass.join(" ")} onClick={() => handlerSwitch(i)} onMouseOver={() => handlerSwitch(i)} key={i} />
            })}
          </div>
        </div>
        }
        
      </div>
    </div>
  )
}
