import React from "react"
import Layout from "../components/layout"
import { useIntl } from "gatsby-plugin-intl"
import "../sass/animate.css"
import { defaultIntl } from "../utils"
import Banner from "../includes/about/banner"
import About from "../includes/about/about"
import Timeline from "../includes/about/timeline"
import Contact from "../includes/about/contact"

const AboutPage = () => {
  const intl = useIntl() || defaultIntl
  return (
    <Layout intl={intl} page="about">
      <Banner intl={intl} />
      <About intl={intl} />
      <Timeline intl={intl} />
      <Contact intl={intl} />
    </Layout>
  )
}

export default AboutPage;