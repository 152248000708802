import { defaultIntl, SEQ, window, _isMobile } from "../../utils"
import React, { useState, useEffect } from "react"
import "./timeline.sass"
import classNames from 'classnames'
import TimeLineData from "./timeline-list.json"

const Timeline = ({ intl = defaultIntl }) => {
  const [lang] = useState(intl.locale)
  const [data, setData] = useState(TimeLineData[lang])
  useEffect(() => {
    setData(TimeLineData[lang])
  }, [lang])
  return (
    <div className="section timeline">
      <div className="container">
        <h3 className="section-title">{intl.formatMessage({ id: 'about.timeline.title' })}</h3>
        <h3 className="section-subtitle">{intl.formatMessage({ id: 'about.timeline.desc' })}</h3>
        <div className="time-wrapper">
          {
            data.map((item, index) => (
              <div className="time-col" key={index}>
                <Item item={item[0]} />
                <Item item={item[1]} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
export default Timeline

const Item = ({ item }) => (
  <div className={classNames(`time-item ${item.class}`)}>
    <div className="time--body">
      <div className="icon"><img src={require(`../../images/${item.icon}.png`)} alt="" /></div>
      <div className="content">
        <div className="title">{item.date}&nbsp;&nbsp;{item.event}</div>
        <p className="txt">{item.desc}</p>
        {
          item.sub ?
            item.sub.map((subitem)=>(
              <React.Fragment key={subitem.date}>
                <div className="title">{subitem.date}&nbsp;&nbsp;{subitem.event}</div>
                <p className="txt">{subitem.desc}</p>
              </React.Fragment>
            ))
            :null
        }
      </div>
    </div>
  </div>
)
