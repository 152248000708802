import { defaultIntl, EventBus, Slider, _isMobile } from "../../utils"
import React, { useEffect, useState } from "react"
import VisibilitySensor from "react-visibility-sensor"
import "./about.sass"
import ModalVideo from "react-modal-video"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const PlayList = [
  {
    id: "l8IR0ugtCmc",
    cover: "pic1",
    content: "video_title1",
  },
  {
    id: "IeLcVFgy2Hc",
    cover: "pic2",
    content: "video_title2",
  },
  {
    id: "EycWAFJ1Iyo",
    cover: "pic3",
    content: "video_title3",
  },
  {
    id: "3UZ1w31UVus",
    cover: "pic4",
    content: "video_title4",
  },
  {
    id: "DYiOHOIluCw",
    cover: "pic5",
    content: "video_title5",
  },
]
const About = ({ intl = defaultIntl }) => {
  const [visible, setVisible] = useState(false)
  const [modal, setModal] = useState(false)
  const [videoId, setId] = useState(null)

  const openModal = () => {
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
    setId(null)
  }

  return (
    <VisibilitySensor onChange={v => setVisible(v)}>
      <div className="section about" id="aboutus">
        <div className="container">
          <div className="text-container">
            <h3 className="section-title">{intl.formatMessage({ id: "about.about.title" })}</h3>
            <p className="section-text">{intl.formatMessage({ id: "about.about.desc" })}</p>
          </div>
          <div className="video-list">
            <VideoPlayer list={PlayList} openModal={openModal} setId={setId} intl={intl} />
          </div>
        </div>
        <ModalVideo channel="youtube" isOpen={videoId && modal} videoId={videoId} onClose={closeModal} />
      </div>
    </VisibilitySensor>
  )
}
export default About

const Arrow = ({ className, style, onClick }) => <div className={className} style={{ ...style, display: "block" }} onClick={onClick} />

const VideoPlayer = ({ list, openModal, setId, intl }) => {
  const [isReady, setReady] = useState(false)
  useEffect(() => {
    const unsubscribe = EventBus.subscribe(e => {
      const { Ready } = EventBus.getState()
      if (Ready) {
        setReady(Ready)
        unsubscribe()
      }
    })
  }, [])

  const settings = {
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    dots: true,
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <Slider {...settings}>
      {list.map((item, index) => (
        <div className="card-item" key={index}>
          <Card item={item} openModal={openModal} setId={setId} intl={intl} />
        </div>
      ))}
    </Slider>
  )
}

const handleClick = (id, openModal, setId) => {
  setId(id)
  openModal()
}

const Card = ({ item, openModal, setId, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      pic1: file(relativePath: { eq: "about/about_pic1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic2: file(relativePath: { eq: "about/about_pic6.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic3: file(relativePath: { eq: "about/about_pic7.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic4: file(relativePath: { eq: "about/about_pic4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic5: file(relativePath: { eq: "about/about_pic5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="card">
      <div className="card-image">
        <Img className="video-image" fluid={data[item.cover].childImageSharp.fluid} src={item.imgUrl} alt="Placeholder image" />
      </div>
      <div className="title">{intl.formatMessage({ id: `about.about.${item.content}` })}</div>
      <div className="btn-container">
        <button className="open-video" onClick={() => handleClick(item.id, openModal, setId)}>
          {intl.formatMessage({ id: "about.about.watch_video" })}
        </button>
      </div>
    </div>
  )
}

const Player = ({ player_id, onReady = () => {}, onPlay = () => {} }) => {
  const [player, setPlayer] = useState(null)
  const [playState, setPlayState] = useState(-1)
  useEffect(() => {
    const isMobile = _isMobile()
    const _player = new window.YT.Player(player_id, {
      height: "274",
      width: "366",
      videoId: player_id,
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    })
    setPlayer(_player)
    onReady(_player)
  }, [])

  useEffect(() => {
    player && onPlay(playState === 1 ? player : null)
  }, [player, playState])

  const onPlayerReady = () => {
    //console.log("onPlayerReady")
  }
  const onPlayerStateChange = e => {
    setPlayState(e.data)
  }

  return <div id={player_id} />
}
