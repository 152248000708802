/* eslint-disable react/prop-types */
import { defaultIntl } from "../../utils"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./banner.sass"

const isBrowser = typeof window !== "undefined"
// const [weixin, setWeixin] = useState()

const Banner = ({ intl = defaultIntl }) => {
  const data = useStaticQuery(graphql`
    query {
      pic1: file(relativePath: { eq: "about/head-pic1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const isWeixin = () => {
    if(isBrowser){
      const { userAgent: u } = window.navigator
      return {
        weixin: u.includes("MicroMessenger"), //是否微信 
      }
    }
    return {
      weixin: false
    }
  }

  const { weixin } = isWeixin()

  const handleLinkClick = e => {
    e.preventDefault()
    if (weixin) {
      window.location.href = 'https://wechat.fantuan.ca/others/download.html'
    } else {
      window.location.href = 'https://fantuan.go.link/?adj_t=b5m1oee'
    }
  }

  return (
    <div className="banner">
      <div className={classNames("banner--bg animated slow",)}>
        <Img className="bg-size" fluid={data.pic1.childImageSharp.fluid} alt="fantuan pic" />
        <div className="banner--body">
          <div className="container">
            <div className="vertical">
              <div className="banner--content">
                <div className={classNames("slogan ",)}>
                  <pre>{ReactHtmlParser(intl.formatMessage({ id: "about.banner.title" }))}</pre>
                </div>
                <div className={classNames("button-wrapper ",)}>
                  <div className="visit">
                    <a href="#" target="_blank" onClick={handleLinkClick} rel="noopener noreferrer">
                      {intl.formatMessage({ id: "about.banner.btn" })}
                      <i className="material-icons">chevron_right</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
